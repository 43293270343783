///include /assets/js/app/p/brand.js
"use strict";


class SBasketSummaryLoginPage {
    init() {
        this.view = new Vue({
            el: '#vue-container-login-summary',
            name: 'basket-summary',

            data() {
                return {
                    basket: AnyBasket.inst,
                    basketItemPeriod: null,
                    brand: null,
                    catalogue: UserCatalogue.inst,
                    currency: null,
                    domainPeriod: null,
                    /** @type {{[name: string]: ?*}} */
                    domainSearchCache: {},
                    recaptureReturned: false,
                    createAccount: false,
                    passwordPattern: PasswordValidator.comboPattern.source,
                    query: decode_query_params(location.search),
                    params: new URLSearchParams(window.location.search),
                    password: "",
                    passwordConfirm: "",
                    settings: SharedSettings.inst,
                    submitted: false,
                    termsRead: false,
                    usingInvoice: false,
                    get basketItemCount() {
                        return(
                            this.basket.itemsFlat && this.basket.itemsFlat.length
                        )
                    },
                    get currencyOptions() {
                        if( this.catalogue.config ){
                            var options = [];
                            if(
                                this.catalogue.config &&
                                this.catalogue.config.supportedCurrencies
                            ) {
                                for(const currency of Object.keys(this.catalogue.config.supportedCurrencies) ){
                                    if( Catalogue.currencies[currency] ){
                                        options.push({
                                            text: `${currency} (${Catalogue.currencies[currency].symbol})`,
                                            id: currency
                                        });
                                    }
                                }
                            }
                            return options.length > 1 ? options : null;
                        } else {
                            return null;
                        }
                    },
                    get loading() {
                        return !(
                            this.brand
                        );
                    },
                    recaptureReady() {
                        this.recaptureReturned = grecaptcha.getResponse();
                        if( this.recaptureReturned ){
                            this.$nextTick( () => $("#register_form").submit() );
                        }
                    },
                    checkForm(e) {
                        this.populateRedirect()
                        if( this.brand.togglePrefs.registerReCaptcha && !!this.recaptureReturned) return true;
                        if( (this.brand.termsConditionsLink || this.brand.termsConditions) && !this.termsRead ) {
                            this.submitted = true;
                            e.preventDefault();
                        } else if ( this.password != this.passwordConfirm ) {
                            this.submitted = true;
                            e.preventDefault();
                        } else {
                            var f = $("#register_form");
                            f.validate();

                            if( this.brand.togglePrefs.registerReCaptcha ) {
                                if( f.valid() ){
                                    grecaptcha.execute();
                                }
                                e.preventDefault();
                                return false;
                            } else {
                                return f.valid();
                            }
                        }
                    },
                    populateRedirect() {
                        $('.redirect').val( this.params.get("r") );
                    },

                    get hideNavigationOnSignupPage() {
                        if(this.settings.config && this.settings.config.prefs) {
                            return this.settings.config.prefs.hideNavigationOnSignupPage
                        } else {
                            return true
                        }
                    },

                    /**
                     *
                     * @param {string} domain_name
                     * @returns {Promise<*>}
                     */
                    async cacheCheckDomain(domain_name) {
                        this.domainSearchCache = Object.assign(
                            {[domain_name]: null},
                            this.domainSearchCache
                        )
                        const r = await $.ajax({
                            method: "get",
                            url: `/a/domain-search/${domain_name}`,
                            data: {
                                noexpand: 1,
                                detailed: 1,
                            }
                        })
                        this.domainSearchCache[domain_name] = r[1]
                    },

                    async changeCurrency(currency) {
                        await this.basket.changeCurrency(currency);
                        location.reload();
                    },

                    /**
                     *
                     * @param {SBasketItem} basketItem
                     * @param {string} gpt
                     * @param {number} pm
                     * @param {boolean} ptWipe
                     */
                    async changePaymentTypePeriod(basketItem, gpt, pm, wipePaymentRef = false) {
                        if(
                            gpt != basketItem.paymentType ||
                            pm != basketItem.periodMonths
                        ) {
                            if(!this.catalogue.products) {
                                throw new Error("Products not loaded!")
                            }
                            let product = this.catalogue.products.find(
                                p => p.code == basketItem.productCode
                            )

                            if( !product && basketItem.service ) { // Renewal
                                // Check for discontinued
                                product = this.catalogue.discontinuedProducts.find(
                                    p => p.code == basketItem.productCode
                                )
                            }
                            if( !product ){
                                throw new Error("Product not found!")
                            }

                            if( basketItem.action ){
                                product.pricing = product.pricing.filter( d => d.action == basketItem.action );
                            }

                            //@ts-ignore
                            const general = SBasketService.generalTypesForGeneral(gpt)
                            var p;

                            p = product.pricing.filter(
                                p => p.paymentType == gpt
                            ).sort(
                                (a, b) => b.periodMonths - a.periodMonths
                            ).find(
                                p => ( pm == 0 ? p.periodMonths == pm : (
                                    p.periodMonths == pm ||
                                    pm % p.periodMonths == 0
                                ) )
                            )
                            if( !p ){
                                var cpm = ((basketItem.serviceConfiguration && basketItem.serviceConfiguration.periodMonths) ? basketItem.serviceConfiguration.periodMonths : 0);
                                p = product.pricing.filter(
                                    p => general.includes(p.paymentType)
                                ).sort(
                                    (a, b) => b.periodMonths - a.periodMonths
                                ).find(
                                    p => ( pm == 0 ? p.periodMonths == cpm : (
                                        p.periodMonths == pm ||
                                        pm % p.periodMonths == 0
                                    ) )
                                )
                            }
                            if( !p && pm == 0 ){
                                p = product.pricing.filter(
                                    p => general.includes(p.paymentType)
                                ).sort(
                                    (a, b) => b.periodMonths - a.periodMonths
                                ).find(
                                    p => p.periodMonths == 1
                                )
                            }

                            if(!p || p.price === null) {
                                throw new Error(
                                    `No price found given ${gpt} -> ${general}`
                                )
                            }
                            /** @type {number} */
                            let base_price
                            const pricingPrice = p.managedMarkedUpPrice ? p.managedMarkedUpPrice : p.price
                            if(
                                basketItem.serviceType == "domain" &&
                                basketItem.name &&
                                await this.catalogue.domainMayBePremium(basketItem.name)
                            ) {
                                const domain_name = basketItem.name
                                if(!this.domainSearchCache[domain_name]) {
                                    await this.cacheCheckDomain(domain_name)
                                }
                                if(this.domainSearchCache[domain_name].price) {
                                    const rate =
                                        await this.catalogue.preloadSingle("currencyRate")
                                    base_price = Math.round(
                                        +this.domainSearchCache[domain_name].price * rate * 100
                                    ) / 100
                                } else {
                                    base_price = pricingPrice
                                }
                            } else {
                                base_price = pricingPrice
                            }
                            const price = (p.periodMonths == pm) ?
                                base_price :
                                Math.round(
                                    100 * base_price * pm / p.periodMonths
                                ) / 100

                            const us_sales_tax = this.catalogue.config.USSalesTaxEnabled;
                            const vat_rate =
                                await this.catalogue.preloadSingle("userVATRate")

                            try {
                                var d = {
                                    paymentType: p.paymentType,
                                    periodMonths: pm,
                                };
                                if( wipePaymentRef ){
                                    d['paymentRef'] = null;
                                }

                                if (us_sales_tax && vat_rate < 1) {
                                    // For US sales tax vat would be 0.1 instead of 10 so original calculation doesn't work
                                    var quotedFull = price + (price * +vat_rate);
                                    var quotedVat  = price * +vat_rate;
                                } else {
                                    var quotedFull = Math.round(price * (100 + +vat_rate)) / 100;
                                    var quotedVat  = Math.round(price * +vat_rate) / 100;
                                }

                                if( (wipePaymentRef && basketItem.paymentRef) || p.paymentType != basketItem.paymentType || pm != basketItem.periodMonths || quotedFull != basketItem.quotedPriceFull || quotedVat !=  basketItem.quotedVAT ){
                                    await basketItem.modify(
                                        d,
                                        quotedFull,
                                        quotedVat,
                                        this.catalogue.currency
                                    )
                                    this.basket.reload(["items","content"]);
                                }
                            } catch(e) {
                                throw e
                            }
                        }
                    },

                    /**
                     * @param {SBasketItem} item
                     * @returns {?{id: string, text: string}[]}
                     */
                    paymentPeriodOptionsFor(item) {
                        if(!this.catalogue.products) return null
                        if(item.action == 'transfer') return []
                        const product = this.catalogue.products.find(
                            p => p.code == item.productCode
                        )
                        if(!product) return []
                        if(typeof SBDomainProduct !== 'undefined' && product instanceof SBDomainProduct) {
                            const domain_name = item.name
                            if(!domain_name) return []
                            if( item.service ){ // renewal
                                return this.domainPeriod[product.tld].map(
                                    y => ({
                                        id: y * 12,
                                        text: Catalogue.intervalMonthsLabel(+y * 12, true),
                                    })
                                );
                            } else {
                                if(
                                    domain_name &&
                                    !this.domainSearchCache.hasOwnProperty(domain_name)
                                ) {
                                    this.cacheCheckDomain(domain_name)
                                }
                                if(
                                    this.domainSearchCache[domain_name] &&
                                    this.domainPeriod
                                ) {
                                    const r = this.domainSearchCache[domain_name]
                                    const max_years = r.maxYears !== undefined ?
                                        r.maxYears :
                                        10
                                    const years = this.domainPeriod[product.tld].filter(
                                        y => y <= max_years
                                    )
                                    return years.map(
                                        y => ({
                                            id: y * 12,
                                            text: Catalogue.intervalMonthsLabel(+y * 12, true),
                                        })
                                    )
                                } else {
                                    return null
                                }
                            }
                        } else {
                            const prices = product.pricing.slice()
                            // ^--v---< Remember, sort is *in-place*
                            prices.sort(
                                (a,b) => b.paymentType.localeCompare(a.paymentType)
                            )
                            /** @type {{[period: number]: boolean}} */
                            const seen = {}
                            for(const price of prices) {
                                seen[price.periodMonths] = true
                            }
                            return Object.keys(seen).map(
                                m => ({
                                    id: m,
                                    text: Catalogue.intervalMonthsLabel(+m, true),
                                })
                            )
                        }
                    },
                }
            },
            watch: {
                /**
                 *
                 * @param {SBasketItem[]} items_flat
                 */
                "basket.itemsFlat": function(items_flat) {
                    if(items_flat) {
                        /**
                         * @type {{[ref: string]: number}}
                         */
                        const basket_item_period = {}
                        for(const i of items_flat) {
                            if(i.ref) {
                                basket_item_period[i.ref] = i.periodMonths
                            }
                        }
                        this.basketItemPeriod = basket_item_period
                        for(const i of items_flat) {
                            this.$watch(
                                () => i.ref && basket_item_period[i.ref],
                                (n, o) => {
                                    if(
                                        n !== undefined &&
                                        o !== undefined &&
                                        n !== null &&
                                        n != o
                                    ) {
                                        return this.changePaymentTypePeriod(
                                            i,
                                            i.paymentType,
                                            +n
                                        )
                                    }
                                }
                            )
                        }
                    }
                },
                "catalogue.currency": function(c) {
                    this.currency = c;
                },
                currency(n,o) {
                    if( o && n && n != this.catalogue.currency ){
                        this.changeCurrency(n);
                    }
                }
            },
            components: {
                shopNav: ShopNav,
                languageSelector: languageSelector,
                vueSelect: VueSelect,
            },
            mounted() {
                if(this.query.register) {
                    this.createAccount = true
                }
                if( this.query.error == 'recaptcha' ){
                    this.createAccount = true
                }
                this.catalogue.assertExists()
                $.ajax(`/a/domain-period`).done( p => this.domainPeriod = p );
            },
        });
    }
}
